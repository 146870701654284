import { Heading, SelectField, Text } from '@puregym/ui';
import { useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.baseSpacing};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  padding: ${({ theme }) => theme.spacing.doubleSpacing};
  color: #ffffff;
  margin: 0 auto;
  z-index: 10;
  width: 21em;
  min-width: 320px;

  @media (max-width: 480px) {
    width: 18em;
  }

  ${Heading} {
    color: ${({ theme }) => theme.colors.contrastText};
    margin: 0;
  }

  ${Text} {
    margin: 0;
  }
`;

const HiddenOption = styled.option`
  display: none;
`;

const U16GymPickerBlock = ({ data: { content } }) => {
  const { heading, subHeading, description, gymPicker, joinUrl } = content;
  const [selectedValue, setSelectedValue] = useState('-1');

  const handleGymSelection = (e) => {
    const gym = e.target.value;
    setSelectedValue(gym);
    window.location.href = joinUrl.replace('{gym}', gym);
  };

  return (
    <StyledContainer>
      <Heading>{heading}</Heading>
      <Text>{subHeading}</Text>
      <SelectField
        defaultValue={selectedValue}
        onChange={handleGymSelection}
        data-testid="Under16GymPickerSelect"
        hideLabel="true"
      >
        <HiddenOption value="-1" disabled aria-hidden>
          Choose a gym
        </HiddenOption>
        {gymPicker.map((gym) => (
          <option key={gym.id} value={gym.urlSegment}>
            {gym.name}
          </option>
        ))}
      </SelectField>
      <Text>{description}</Text>
    </StyledContainer>
  );
};

export { U16GymPickerBlock };
